import "./Service.css";
import ServiceCard from "../../Components/ServiceCard/ServiceCard.js";
import FooterMob from "../FooterMob/FooterMob";
import ui from "../../Images/ui.png";
import webdev from "../../Images/software.png";
import app from "../../Images/application.png";
import social from "../../Images/social-media.png";
import hostingservic from "../../Images/hostingservices.png";
import domain from "../../Images/http.png";
import cloud from "../../Images/cloud.png";
import mon from "../../Images/mon.png";

function Service() {
  return (
    <>
      <div className="d-flex flex-column w-100">
        <div className="orm psm d-flex flex-column flex-lg-row justify-content-center  align-items-center service-cards-container-1">
          <ServiceCard
            calling={social}
            title="خدمات شبکه های اجتماعی"
            caption="اگر به انواع خدمات شبکه های اجتماعی مثل سئو کانال یا پیج های تلگرام ، اینستاگرام و شبکه های اجتماعی دیگر احتیاج دارید میتوانید به ما اعتماد کنید ."
            url="/social/"
          />
          <div className="scm xsm"></div>
          <ServiceCard
            calling={app}
            title="طراحی اپلیکیشن"
            caption="طراحی انواع اپلیکیشن با انواع کارکرد و هدف یکی از خدمات اصلی ما است . هر گونه اپلیکیشن اندروید و یا ای او اس که احتیاج داشته باشید را میتوانیم طراحی کنیم ."
            url="/application/"
          />
          <div className="scm xsm"></div>
          <ServiceCard
            calling={ui}
            title="طراحی گرافیک"
            caption="هر کسب و کاری به طرح های گرافیکی مانند تیزر تبلیغاتی ، لوگو و موارد دیگر احتیاج دارد . ما میتوانیم برای شما انواع طراحی گرافیک انجام دهیم ."
            url="/design/"
          />
          <div className="scm xsm"></div>
          <ServiceCard
            calling={webdev}
            title="طراحی وبسایت"
            caption="به یک وبسایت احتیاج دارید ؟ ما اینجاییم که به شما کمک کنیم . طراحی اختصاصی وبسایت یکی از خدمات شرکت عماد می باشد ."
            url="/web-design/"
          />
        </div>
        <div className="orm xs-d-none l-d-flex justify-content-center service-cards-container-2">
          <ServiceCard
            calling={mon}
            title="مانیتورینگ"
            caption="وب سایت یا اپلیکیشن شما را با هوشمند ترین سیستم های مانیتورینگ به صورت تمام وقت مانیتور میکنیم"
            // url="/#"
          />
          <div className="scm"></div>
          <ServiceCard
            calling={hostingservic}
            title="سرور های ابری"
            caption="با سرور های ابری ما ، از قدرت و انعطاف پذیری بی نظیری برای تجارتتان بهره مند شوید و به آسمان نیازهایتان برسید ."
            // url="/hosting"
          />
          <div className="scm"></div>
          <ServiceCard
            calling={domain}
            title="ثبت دامنه"
            caption="ما خدمات ثبت دامنه را با سرعت آنی به همراه پشتیبانی فنی حرفه‌ای و قیمت مناسب ، به شما ارائه می‌دهیم ."
            url="/hosting/"
          />
          <div className="scm"></div>
          <ServiceCard
            calling={cloud}
            title="میزبانی و هاستینگ"
            caption="دنبال یک هاستینگ حرفه ای هستید؟ هاست های ایمن و پر سرعت ما با پشتیبانی تخصصی ما میتواند یکی از بهترین گزینه های پیش رو شما باشد."
            url="/hosting/"
          />
        </div>
      </div>
      <div className="xs-d-block l-d-none">
        <FooterMob />
      </div>
    </>
  );
}

export default Service;
